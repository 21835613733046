import { useState } from 'react';
import type { NextPage } from 'next';
import Link from 'next/link';

import { useForm } from 'react-hook-form';
import Router from 'next/router';

import type { GmUserTokenSuccessResponse } from '@app/api';
import { api, useAuth, useLocale } from '@app/hooks';
import { Button, Form, AuthLayout } from '@app/components';

const GRANT_TYPE = 'password';

const Login: NextPage = () => {
  const [error, setError] = useState<null | string>(null);
  const { storeToken } = useAuth();
  const SCOPE_OPTIONS = {
    scope: 'pages.login',
  };
  const { t } = useLocale();
  const { mutate, isLoading } = api.useGeneralManagerUserLogin({
    onSuccess: (data: GmUserTokenSuccessResponse) => {
      storeToken(data);

      setTimeout(() => {
        Router.push('/gateway');
      }, 100);
    },
    onError: () => {
      setError(t('errors.wrongEmailOrPassword', SCOPE_OPTIONS));
    },
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onChange',
  });

  function handleSignIn(data: { [x: string]: any }) {
    setError(null);
    mutate({
      grant_type: GRANT_TYPE,
      client_id: process.env.NEXT_PUBLIC_API_CLIENT_ID,
      client_secret: process.env.NEXT_PUBLIC_API_CLIENT_SECRET,
      ...data,
    });
  }

  return (
    <AuthLayout isLoading={isLoading}>
      <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full">
          <div className="text-center mb-10">
            <h2 className="text-3xl font-semibold text-gray-900">{t('labels.title', SCOPE_OPTIONS)}</h2>
            <p className="text-sm text-gray-500 mt-1">{t('labels.description', SCOPE_OPTIONS)}</p>
          </div>
          <form onSubmit={handleSubmit(handleSignIn)}>
            {error && (
              <div className="flex items-center justify-between flex-wrap bg-red-100 border border-red-400 p-2 rounded-2 mb-4">
                <p className="text-3.5 text-red-400">
                  <span>{error}</span>
                </p>
              </div>
            )}
            <Form.Input
              type="text"
              id="email"
              label={t('form.email.label', SCOPE_OPTIONS)}
              className="mb-4"
              placeholder={t('form.email.placeholder', SCOPE_OPTIONS)}
              {...register('email', {
                required: { value: true, message: t('form.email.errors.required', SCOPE_OPTIONS) },
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: t('form.email.errors.pattern', SCOPE_OPTIONS),
                },
              })}
              error={errors.email}
            />
            <Form.Input
              type="password"
              id="password"
              label={t('form.password.label', SCOPE_OPTIONS)}
              className="mb-8"
              placeholder={t('form.password.placeholder', SCOPE_OPTIONS)}
              {...register('password', {
                required: { value: true, message: t('form.password.errors.required', SCOPE_OPTIONS) },
              })}
              error={errors.password}
            />
            <div className="flex justify-between">
              <Form.Checkbox id="remember" name="remember" label={t('form.rememberMe.label', SCOPE_OPTIONS)} />
              <Link href="/forgot-password">
                <a className="text-blue-300 font-medium underline text-sm">{t('actions.forgotPassword', SCOPE_OPTIONS)}</a>
              </Link>
            </div>
            <Button type="submit" theme="blue" className="mt-8" label={t('form.submit', SCOPE_OPTIONS)} />
          </form>
        </div>
      </div>
    </AuthLayout>
  );
};

export default Login;
